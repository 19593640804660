@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
}

body {
    background: #F4F4F4;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    line-height: 1.50;
    scroll-behavior: smooth !important;
}

nav {
    /*background: #918E8C;*/
    background: #9a979c;
    box-shadow: 0 0 2px rgba(33, 37, 41, 0.25);
}

.nav-link {
    color: #F4F4F4;
    transition: all .3s ease-in-out;
}

.nav-link:hover {
    opacity: 0.8;
    transition: all .3s ease-in-out;
}

nav .d-flex {
    color: #F4F4F4;
    transition: all .3s ease-in-out;
}

nav .d-flex a {
    transition: all .3s ease-in-out;
    color: #F4F4F4;
    text-decoration: none;
}

nav .d-flex a:hover {
    transition: all .3s ease-in-out;
    opacity: 0.3;
}

p {
    font-size: 16px;
    text-align: justify;
    line-height: 1.8;
}

figure {
    width: 100%;
}

a {
    text-decoration: none;
    color: #212529;
}

h4 {
    font-size: 20px;
    line-height: 1.25;
}

.dropdown-menu {
    background: #F4F4F4;
}

.back-to-top {
    position: fixed;
    bottom: 60px;
    right: 20px;
    font-size: 18px;
    background: #F4F4F4;
    color: #212529;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 8px #ccc;
    transition: all .3s ease-in-out;
}

.back-to-top:hover {
    transition: all .3s ease-in-out;
    background: #212529;
    box-shadow: 0 0 8px #212529;
    color: #F4F4F4;
}


.card {
    transition: 0.3s ease-in-out;
    border: none;
    background: #e7e7e7;
    border-radius: 5px;
    color: #212529;

    &:hover {
        transition: 0.3s ease-in-out;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

        &.opacity-50 {
            box-shadow: none;
        }
    }

    .card-header {
        border-bottom: none;
    }

    .card-footer {
        border-top: none;
        background: #e7e7e7;
    }
}

.btn-secondary {
    border: none;
    background-color: #8a8a8a;
    transition: 0.3s ease-in-out;
}

.btn-secondary:hover {
    transition: 0.3s ease-in-out;
    border: none;
    background-color: #8a8a8a;
}

form {
    background: #fff;
    margin: 2% auto;
    width: 20rem;
    padding: 20px;
}

.author {
    text-align: end;
    color: #212529;

    & a {
        background: #6e44ff;
        color: #f3f3f3;
        padding: 5px;
        border-radius: 5px;
        transition: all .3s ease-in-out;

        &:hover {
            transition: all .3s ease-in-out;
            box-shadow: 0 0 12px rgba(110, 68, 255, 0.25);
        }
    }
}

.vk {
    background: #5181b8;
    padding: 10px;
    color: white;
    border-radius: 10px;
}